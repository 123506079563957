import {Button, Card, CardActions, CardContent, Typography} from "@mui/material";

const ProductOption = ({legend, title, body}:any) => {
    return (
        <Card className='product-option'>
            <CardContent>
                <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {legend}
                </Typography>
                <Typography variant="h5" component="div">
                    {title}
                </Typography>
                <Typography variant="body2">
                    {body}
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small">Learn More</Button>
            </CardActions>
        </Card>
    );
}

export default ProductOption;