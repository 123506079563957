import {Button} from "primereact/button";
import edits from "./img/full/team_v3.png";
import {BsChevronRight,BsChevronLeft} from "react-icons/bs";
import {RadioButton} from "primereact/radiobutton";

const sizes = ['Only me', '2-5', '6-10', '11-15', '16-25', '25-50', '51-100', '101-500', '501-100'];

const StepFour = ({ accountSize, setAccountSize, prev, next }:any) => {
    const submitStep = () => { next(); }

    return (<div className='signup-main'>
        <div className='signup-form green'>
            <div className='form-content'>
                <div>
                    <div>
                        <h2>How many people are on your team?</h2>
                        <br/>
                    </div>
                    <div className='radio-horizontal-list'>
                        {
                            sizes.map((opt, i) => <div key={'prf-'+i} className="type-option" onClick={() => {
                                setAccountSize(i.toString());
                            }}>
                                <RadioButton
                                    inputId={"account_type"+i}
                                    name="account_type"
                                    value={opt}
                                    checked={accountSize === i.toString()}
                                />
                                <label htmlFor={"account_type"+i}>{opt}</label>
                            </div>)
                        }
                    </div>
                </div>
                <div className='form-next green'>
                    <Button onClick={prev} >
                        <BsChevronLeft />&nbsp;Back
                    </Button>
                    &nbsp;&nbsp;
                    <Button onClick={submitStep} disabled={accountSize === ''} >
                        Continue &nbsp; <BsChevronRight />
                    </Button>
                </div>
            </div>
        </div>
        <div className='design-right two'>
            <img src={edits} alt='SignUp' />
        </div>
    </div>);
}

export default StepFour;