import {BiMenu} from "react-icons/bi";
import {MouseEventHandler} from "react";
import './style.css';

interface AppBarMenuProps {
    onClick?:MouseEventHandler;
}
const AppBarButton = ({onClick}:AppBarMenuProps) => {


    return <div className='app-bar-btn' onClick={onClick}>
        <BiMenu size={48} />
    </div>
}

export default AppBarButton;