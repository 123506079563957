import {useRef, useState} from "react";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {BsChevronRight,BsChevronLeft} from "react-icons/bs";
import p1 from "./img/full/coding_v4.png";

const StepTwo = ({ email, name, setName, password, setPassword, accountName, setAccName, prev, next }:any) => {
    const [isValid, setValidity] = useState({ name:true, password:true, accountName: true});
    const passwordRef = useRef<HTMLInputElement>(null);
    const accountNameRef = useRef<HTMLInputElement>(null);

    const validate = () => {
        let validity:any = {};
        const accountNameRegex = /^[a-zA-Z0-9-_]+$/;
        validity.name = name.trim() !== '';
        validity.password = password.trim() !== '' && password.length >= 8;
        validity.accountName = accountNameRegex.test(accountName.trim());
        setValidity(validity);
        return validity.name && validity.password && validity.accountName;
    }
    const submitStep = () => {
        if(validate()){
            next();
        }
    }

    return (<div className='signup-main'>
        <div className='signup-form green'>
            <div className='form-content'>
                <div>
                    <div>
                        <h2>Create your account</h2>
                        <br/><br/>
                    </div>
                    <div className='form-controls'>
                        <label htmlFor="name">Full name</label>
                        <InputText
                            id="full_name"
                            autoFocus={true}
                            className={'form-control '+(!isValid.name ?'p-invalid':'')}
                            aria-describedby="full_name-help"
                            placeholder='Enter your full name'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            onKeyDown={(e) => {if(e.key === 'Enter') passwordRef.current?.focus()}}
                        />
                        <br/><br/>
                        <label htmlFor="password">Password</label>
                        <InputText
                            ref={passwordRef}
                            id="password"
                            type='password'
                            className={'form-control '+(!isValid.password ? 'p-invalid':'')}
                            aria-describedby="password-help"
                            placeholder='Enter at least 8 characters'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            onKeyDown={(e) => {
                                if(e.key === 'Enter' && accountName.trim() !== '') {
                                    submitStep();
                                }else if(e.key === 'Enter'){
                                    accountNameRef.current?.focus();
                                }
                            }}
                        />
                        <br/><br/>
                        <label htmlFor="account_name">Account name</label>
                        <InputText
                            ref={accountNameRef}
                            id="account_name"
                            className={'form-control '+(!isValid.accountName ? 'p-invalid':'')}
                            aria-describedby="account-name-help"
                            placeholder='Enter your organization name'
                            value={accountName}
                            onChange={(e) => setAccName(e.target.value.replaceAll(' ', ''))}
                            onKeyDown={(e) => {
                                if(e.key === 'Enter') {
                                    submitStep();
                                }
                            }}
                        />
                        {!isValid.accountName && <small className='p-error'>Invalid name, is empty or has invalid chars</small>}
                    </div>
                </div>
                <div className='form-next green'>
                    <Button onClick={prev} >
                        <BsChevronLeft />&nbsp;Back
                    </Button>
                    &nbsp;&nbsp;
                    <Button onClick={submitStep} >
                        Continue &nbsp; <BsChevronRight />
                    </Button>
                </div>
            </div>
        </div>
        <div className='design-right'>
            <img src={p1} alt='SignUp' />
        </div>
    </div>);
}

/*
<div className='people-mix'>
    <img src={p1} className='people-anim' />
    <img src={p6} />
    <img src={p3} />
    <img src={p7} />
    <img src={p4} />
    <img src={p8} />
    <img src={p2} />
    <img src={p5} />
</div>
*/
export default StepTwo;