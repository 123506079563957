import {BsChevronDown, BsChevronUp} from "react-icons/bs";

const NavOption = ({ url = '#', label, isActive, showChevron, onClick }:any) => {
    return <a href={url} className={'nav-option '+(isActive?'nav-active':'')} onClick={onClick}>
        {label}&nbsp;
        {
            showChevron && (
                isActive ?
                    <BsChevronUp size={10} /> :
                    <BsChevronDown size={10}/>
            )
        }
    </a>
}

export default NavOption;