type queryMethod = 'GET'|'POST'|'PUT'|'PATCH'|'DELETE';
export const baseQuery = async (path:string, method:queryMethod, payload?:any) => {
    const DOR_API_TARGET:string = process.env.REACT_APP_API_HOST || `https://${window.location.host}/api/v1/`;

    const options:RequestInit = {
        method: method,credentials: "include",
        headers: {'Content-Type': 'application/json'}
    }

    if(method !== 'GET') options.body = payload;

    return await fetch(
        DOR_API_TARGET+path,
        options
    ).then((data) => data.json());
}