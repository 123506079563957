import AppbarOption from "./appbaroption";
import {Divider} from "primereact/divider";
import './style.css';
import {BiLogIn} from "react-icons/bi";
import {ImUserPlus} from "react-icons/im";
import {GrContact} from "react-icons/gr";
import {PiGraphDuotone} from "react-icons/pi";
const AppBarMenu = ({navigate}:any) => {


    return <div className='active-menu-content'>
        <div className='app-bar-menu-list'>
            <AppbarOption label='Products' />
            <AppbarOption label='Teams' />
            <Divider/>
            <AppbarOption icon={<PiGraphDuotone />} label='Pricing' />
            <AppbarOption icon={<GrContact />} label='Contact' />
            <Divider/>
            <AppbarOption icon={<BiLogIn />} label='Log in' />
            <AppbarOption icon={<ImUserPlus />} label='Get Started' onClick={() => {
                navigate('signup');
            }} />
        </div>
    </div>;
}

export default AppBarMenu;