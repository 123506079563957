import {Button} from "primereact/button";
import edits from "./img/full/coding_v4.png";
import {BsChevronRight,BsChevronLeft} from "react-icons/bs";
import {RadioButton} from "primereact/radiobutton";

const primary = ['Work', 'Personal', 'School', 'Non Profits'];
const secondary_work = ['Business owner', 'Team leader', 'Team member', 'Freelancer', 'Director', 'C-Level', 'VP'];
const secondary_school = ['Undergraduate student', 'Graduate student', 'Faculty member', 'Other'];
const secondary_nonprofit = ['Board member', 'Executive', 'Employee', 'Volunteer', 'IT staff', 'Other'];

const StepThree = ({ account_type, setType, secondary_type, setSecondaryType, prev, next }:any) => {
    const submitStep = () => { next(); }

    const getSecondary = () => {
        switch (account_type){
            case '0':
                return secondary_work;
            case '2':
                return secondary_school;
            case '3':
                return secondary_nonprofit;
            default:
                return [];
        }
    }

    return (<div className='signup-main'>
        <div className='signup-form green three'>
            <div className='form-content'>
                <div>
                    <div>
                        <h2>Hey there, what brings you here today?</h2>
                        <br/>
                    </div>
                    <div className='radio-horizontal-list'>
                        {
                            primary.map((opt, i) => <div key={'prtwo-'+i} className="type-option" onClick={(e) => {
                                setType(i.toString());
                                setSecondaryType('');
                            }}>
                                <RadioButton
                                    inputId={"account_type"+i}
                                    name="account_type"
                                    value={opt}
                                    checked={account_type === i.toString()}
                                />
                                <label htmlFor={"account_type"+i}>{opt}</label>
                            </div>)
                        }
                    </div>
                    <br/><br/>

                    {
                        account_type !== '1' && account_type !== '' && <div>
                            <h2>What best describes your current role?</h2>
                            <br/>
                        </div>
                    }
                    <div className='radio-horizontal-list'>
                        {
                            getSecondary().map((opt, i) => <div key={'srtwo-'+i} className="type-option" onClick={(e) => setSecondaryType(i.toString())}>
                                <RadioButton
                                    inputId={"secondary_type"+i}
                                    name="account_type"
                                    value={opt}
                                    checked={secondary_type === i.toString()}
                                />
                                <label htmlFor={"secondary_type"+i}>{opt}</label>
                            </div>)
                        }
                    </div>
                </div>
                <div className='form-next green'>
                    <Button onClick={prev} >
                        <BsChevronLeft />&nbsp;Back
                    </Button>
                    &nbsp;&nbsp;
                    <Button onClick={submitStep} disabled={account_type === '' || (account_type !== '1' && secondary_type === '') } >
                        Continue &nbsp; <BsChevronRight />
                    </Button>
                </div>
            </div>
        </div>
        <div className='design-right'>
            <img src={edits} alt='SignUp' />
        </div>
    </div>);
}

export default StepThree;