import {useState} from "react";
import {Divider} from "primereact/divider";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import gear from "./img/plain_gear.svg";
import gear_shadow from "./img/plain_gear_shadow.svg";

const StepOne = ({email, setEmail, setAccName, next}:any) => {
    const [isValid, toggleValid] = useState(true);
    const submitStep = () => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if(emailRegex.test(email)){
            toggleValid(true);
            const domain = /@([a-zA-Z0-9.-]+)\.[a-zA-Z]{2,4}$/;
            const match = email.match(domain);
            const accName = (match[1][0].toUpperCase())+match[1].slice(1)
            setAccName(accName);
            next();
        }else{
            toggleValid(false);
        }
    }

    return (<div className='signup-main'>
        <div className='signup-welcome'>
            <div className='form-content'>
                <div>
                    <h1><strong>Welcome to </strong>TaskLab.dev</h1>
                    <small>Get started - it's free. NO credit card needed.</small>
                    <Divider/>
                </div>
                <div className='form-controls'>
                    <InputText
                        className={'form-control '+(isValid?'':'p-invalid')}
                        placeholder='name@compayemail.com'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        onKeyDown={(e) => {if(e.key === 'Enter') submitStep()}}
                    />
                    <small className='label-invalid' style={{opacity: isValid ? 0:1}}>Please enter a valid email address</small>
                </div>
                <br />
                <div style={{marginTop: '-1rem'}}>
                    <Button className='form-control' label="Submit" onClick={submitStep} />
                </div>
                <div className='terms_privacy'>
                    <span>By proceeding, you agree to the</span>
                    <br />
                    <span><a href='#Term_of_service'>Terms of Service</a> and <a href='#Privacy_Policy'>Privacy Policy</a></span>
                </div>
            </div>
        </div>
        <div className='design-right'>
            <div className='machine'>
                <div className='gear-container-left'>
                    <img src={gear_shadow} className='gear-shadow' alt='SignUp' />
                    <img src={gear} className='gear' alt='SignUp' />
                </div>
                <div className='gear-container-right'>
                    <img src={gear_shadow} className='reverse-gear-shadow' alt='SignUp' />
                    <img src={gear} className='reverse-gear' alt='SignUp' />
                </div>
            </div>
        </div>
    </div>);
}

export default StepOne;