import {useState} from "react";
import {useNavigate} from "react-router-dom";
import NavOption from "../appbar/navoption";
import ProductsContent from "../products/content";
import GetStarted from "../appbar/getstarted";
import ProductOption from "../products/productoption";
import AppBarButton from "../appbar/appbarbtn";
import AppBarMenu from "../appbar/appbarmenu";
import lunesLogo from './img/logo_v3.svg';
import './style.css';

const Lunes = () => {
    const [showMenu, toggleShow] = useState(true);
    const [content, switchContent] = useState('');
    const navigate = useNavigate();

    const activeContent = () => {
        switch (content) {
            case 'appbar-menu':
                return <AppBarMenu navigate={navigate} />;
            case 'products':
                return <ProductsContent />;
            default:
                return <></>;
        }
    }

    const switchActive = (label:string) => {
        if(content === label){
            switchContent('');
        }else{
            switchContent(label);
        }
    }

    const handleScroll = (e:any) => {
        if(e.target.scrollTop > 50){
            //toggleShow(false);
        }else{
            toggleShow(true);
        }
    }

    return (
        <div className='welcome-main' onScroll={handleScroll}>
            <div className='topNavBar'>
                <div className='navbar-container'>
                    <div className='nav-section'>
                        <a href='#Home'>
                            <img src={lunesLogo} height={35} className='logo' alt={'TaskLab.dev'} />
                        </a>
                        <NavOption
                            showChevron
                            label={'Products'}
                            isActive={content === 'products'}
                            onClick={() => switchActive('products')}
                        />
                        <NavOption
                            showChevron
                            label={'Teams'}
                            isActive={content === 'teams'}
                            onClick={() => switchActive('teams')}
                        />
                    </div>
                    <div className='nav-section'>
                        <NavOption label={'Pricing'} />
                        <NavOption label={'Contact sales'} />
                        <NavOption label={'Log in'} url={window.location.port!==''?`http://auth.localhost.net:3001`:'https://auth.tasklab.dev'}/>
                        <GetStarted navigate={navigate} />
                    </div>
                    <AppBarButton onClick={() => switchActive('appbar-menu')} />
                </div>
                { showMenu && activeContent() }
            </div>

            <div className='welcome-content'>
                <h1 className='h1s'>
                    A multipurpose management tool
                </h1>
                <p className='h3s'>
                    TaskLab has been created with versatility at its core. The goal is to support all aspects of work, help automate tasks and save valuable time.
                </p>

                <div className='product-list'>
                    <ProductOption legend='Explore' title='Imagination is the limit' body='Descubre un mundo de posibilidades para liberar tu creatividad. Crea, colabora y logra más allá de tus expectativas. ¡Comienza tu viaje ahora!' />
                    <ProductOption legend='Experiment' title='Experiment' body='' />
                    <ProductOption legend='Learn' title='Learn' body='' />
                    <ProductOption legend='Improve' title='Improve' body='' />
                </div>

                <div className='product-list-start'>
                    <GetStarted className='small' navigate={navigate} />
                </div>
            </div>
            { content !== '' && showMenu && <div className='blur-background' onClick={() => switchContent('')} /> }
        </div>
    )
}

export default Lunes;