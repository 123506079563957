import {FaArrowRight} from "react-icons/fa";

const GetStarted = ({ className = '', navigate }:any) => {
    return <div className={'get-started-btn '+className} onClick={() => navigate('/signup')}>
        Get Started
        <div className='get-started-arr'>
            <FaArrowRight className='get-started-arrow' size={12} />
        </div>
    </div>
}

export default GetStarted;