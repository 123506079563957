import {Button} from "primereact/button";
import compass from "./img/compass.svg";
import compassNoodle from "./img/compass_noodle.svg";
import {BsChevronRight,BsChevronLeft} from "react-icons/bs";
import {Checkbox} from "primereact/checkbox";

const socials = ['Youtube Ad', 'Facebook','Instagram','Reddit', 'Search Engine (Google, Bing, etc)','Audio ad (Podcast, Spotify)', 'Github', 'TV / Streaming Service', 'Software review sites', 'Consultant', 'Friend / Colleague', 'LinkedIn','Other'];
const StepFive = ({ isLoading, isError, isSuccess, socialTypes, setSocials, prev, next }:any) => {
    const submitStep = () => {
        next();
    }

    return (<div className='signup-main'>
        <div className='signup-form orange'>
            <div className='form-content'>
                <div>
                    <div>
                        <h2>One last question, how did you hear about us?</h2>
                        <br/>
                    </div>
                    <div className='radio-horizontal-list'>
                        {
                            socials.map((opt, i) => <div key={'scf-'+i} className="type-option" onClick={() => {
                                if(socialTypes.includes(i.toString())){
                                    setSocials((prev:string[]) => (prev.filter(s => s !== i.toString())));
                                }else{
                                    setSocials((prev:string[]) => [...prev,i.toString()]);
                                }
                            }}>
                                <Checkbox
                                    inputId={"social_type"+i}
                                    name="social_type"
                                    value={i}
                                    checked={socialTypes.includes(i.toString())}
                                />
                                <label>{opt}</label>
                            </div>)
                        }
                    </div>
                </div>
                <div className='form-next orange'>
                    <Button onClick={prev} disabled={isLoading} visible={!isLoading} >
                        <BsChevronLeft />&nbsp;Back
                    </Button>
                    &nbsp;&nbsp;
                    <Button onClick={submitStep} loading={isLoading} >
                        { isLoading ? '': <>Complete&nbsp;<BsChevronRight /></>}
                    </Button>
                </div>
            </div>
        </div>
        <div className='design-right step-four'>
            <div className='compass'>
                <img src={compass} alt='SignUp' />
                <img src={compassNoodle} className='noodle' alt='SignUp' />
            </div>
        </div>
    </div>);
}

export default StepFive;