import {FaSourcetree} from "react-icons/fa";
import {Divider} from "primereact/divider";
import SidebarOption from "./sidebaroption";
import InfoBlock from "./infoblock";

const ProductsContent = () => {
    return (
        <div className='active-menu-content'>
            <div className='active-content-sidebar'>
                Features by Lunes
                <div className='sidebar-option-list mt-1'>
                    <SidebarOption icon={<FaSourcetree />} label={'Work Management'} />
                    <SidebarOption label={'Sales CRM'} />
                    <SidebarOption label={'Dev'} />
                </div>
                <Divider/>
            </div>
            <div className='active-menu-selected-content'>
                <div className='selected-content-left'>
                    <div>
                        For managing any project, task, and flow
                    </div>
                    <Divider />
                    <div className='grid-container'>
                        <InfoBlock title={'Project management'}>
                            Este es el contenido de la tarjeta
                        </InfoBlock>
                        <InfoBlock title={'Portfolio management'}>
                            Este es el contenido de la tarjeta
                        </InfoBlock>
                        <InfoBlock title={'Task management'}>
                            Este es el contenido de la tarjeta
                        </InfoBlock>
                        <InfoBlock title={'Business operations'}>
                            Este es el contenido de la tarjeta
                        </InfoBlock>
                    </div>
                </div>
                <div className='selected-content-right'>
                    Template center
                </div>
            </div>
        </div>
    )
}

export default ProductsContent;