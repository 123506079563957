import Lunes from "./lunes";
import {Route, Routes} from "react-router-dom";
import Signup from "../signup/signup";

const Welcome = () => {
    return (<div className='application'>
        <Routes>
            <Route path='/signup' element={<Signup />} />
            <Route path='/' element={<Lunes />} />
        </Routes>
    </div>)
}

export default Welcome;