import {useState} from "react";
import StepOne from "./step_one";
import StepTwo from "./step_two";
import StepThree from "./step_three";
import StepFour from "./step_four";
import StepFive from "./step_five";
import {baseQuery} from "../../features/api/dor_api";
import './style.css';

const Signup = () => {
    const [state, changeState] = useState(1);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [accountName, setAccName] = useState('');
    const [account_type, setType] = useState('');
    const [secondary_type, setSecondaryType] = useState('');
    const [accountSize, setAccountSize] = useState('');
    const [socialType, setSocialType] = useState('');

    const [isLoading, toggleLoading] = useState(false);
    const [isError, toggleError] = useState(false);
    const [isSuccess, toggleSuccess] = useState(false);

    const createAccount = async () => {
        toggleLoading(true);
        try {
            const response = await baseQuery('accounts', 'POST', JSON.stringify({
                name: accountName,
                slug: accountName,
                team_size: accountSize,
                seats: 1,
                auth_domain: accountName,
                display_name: name,
                email: email,
                user_type: 1,
                password: password
            }));

            if(response.error){
                toggleError(true);
                toggleLoading(false);
            }else{
                toggleSuccess(true);
                if(window.location.port !== ''){
                    window.location.href = `http://api.localhost.net:${window.location.port}/auth/validate/${response.relay}`;
                }else{
                    window.location.href = `https://${response.slug}.tasklab.dev/api/v1/auth/validate/${response.relay}`;
                }
            }
        }catch (e) {
            toggleError(true);
            toggleLoading(false);
        }
    }

    const nextStep = () => {
        changeState((prev) => prev+1);
    }

    const prevStep = () => {
        changeState((prev) => prev-1);
    }

    switch(state){
        case 2:
            return <StepTwo
                email={email}
                name={name}
                setName={setName}
                password={password}
                setPassword={setPassword}
                accountName={accountName}
                setAccName={setAccName}
                next={nextStep}
                prev={prevStep}
            />;
        case 3:
            return <StepThree
                account_type={account_type}
                setType={setType}
                secondary_type={secondary_type}
                setSecondaryType={setSecondaryType}
                next={nextStep}
                prev={prevStep}
            />;
        case 4:
            return <StepFour
                accountSize={accountSize}
                setAccountSize={setAccountSize}
                next={nextStep}
                prev={prevStep}
            />;
        case 5:
            return <StepFive
                isLoading={isLoading}
                isError={isError}
                isSuccess={isSuccess}
                socialTypes={socialType}
                setSocials={setSocialType}
                next={createAccount}
                prev={prevStep}
            />;
        default:
            return <StepOne
                email={email}
                setEmail={setEmail}
                setAccName={setAccName}
                next={nextStep}
            />;
    }
}

export default Signup;