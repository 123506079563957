import React from "react";
import {PiDotOutlineFill} from "react-icons/pi";
interface AppbarOptionProps {
    label:string;
    icon?:any;
    onClick?:React.MouseEventHandler
}
const AppbarOption = ({ icon, label, onClick }:AppbarOptionProps) => {

    return <div className='app-bar-menu-option' onClick={onClick}>
        { icon ? icon : <PiDotOutlineFill/>}&nbsp;{label}
    </div>
}

export default AppbarOption;